import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import './organize-dnd-item.scss';

const OrganizeDndItem = ({
	isDraggable,
	layout,
	itemId,
	itemImage,
	itemImageAlt,
	classes,
	containerId,
	handleMoveItem
}) => {
	/* Drag functionality */
	const [{ isDragging }, drag, preview] = useDrag({
		type: 'item',
		item: {type: 'item', id: itemId, fromContainerId: containerId},
		canDrag() {return (isDraggable);},
		isDragging(monitor) {return (itemId === monitor.getItem().id);},
		endDrag(monitor) {
			if (handleMoveItem && !(monitor.didDrop())) {
				let item = monitor.getItem();
				handleMoveItem(item.id, item.fromContainerId, null);
			}
		},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});

	/* Hide browser-drawn drag preview image when dragging */
	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	/* Opacity (invisible if dragging) */
	const opacity = (isDragging ? 0 : 1);

	/* Class name */
	let className = 'OrganizeDndItem ' + layout + ' ' + containerId;
	if (classes && classes.length > 0) {classes.forEach((c) => {className += ' ' + c;});}
	
	return (
		<div
			ref={drag}
			className={className}
			style={{opacity}}
		>
			<div 
				className={'OrganizeDndItem-img ' + itemImage + (!isDraggable ? ' disabled' : '')}
				alt={itemImageAlt}
			/>
		</div>
	);
};

OrganizeDndItem.propTypes = {
	isDraggable: PropTypes.bool.isRequired,
	layout: PropTypes.string.isRequired,
	itemId: PropTypes.number.isRequired,
	itemImage: PropTypes.string.isRequired,
	itemImageAlt: PropTypes.string.isRequired,
	containerId: PropTypes.string,
	handleMoveItem: PropTypes.func.isRequired,
	classes: PropTypes.array

};

export default OrganizeDndItem;
